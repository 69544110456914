import React from "react"
import { Helmet } from "react-helmet"
import PageWithBackgroundImage from "../components/pageWithBackgroundImage"

export default function Home({ location }) {
  return (
    <PageWithBackgroundImage location={location}>
      <Helmet>
        <title>Inregistrare - Gazmir.ro</title>
        <meta
          name="description"
          content="Inregistreaza-te pe Gazmir.ro in 3 pasi simplii. Completeaza cu Email, Cod client și Numar Contract Furnizare si beneficiaza de avantajele variantei online."
        />
        <meta
          name="keywords"
          content="inregistrare Cont nou gazmir,inregistrare Cont gazmir.ro,Gazmir inregistrare cont ,inregistrare gazmir,inregistrare gazmir.ro,deschide cont gazmir.ro,cont nou gazmir,Cont gazmir.ro"
        />
      </Helmet>
    </PageWithBackgroundImage>
  )
}
